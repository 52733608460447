import { useState, useEffect } from 'react'
import DawnLight from './dawn-light'
import DawnDark from './dawn-dark'

function Dawn() {
  const [width, setWidth] = useState(1366)

  const handleSize = () => {
    setWidth(window.document.body.getBoundingClientRect().width)
  }

  useEffect(() => {
    handleSize()
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleSize)
      return
    }
    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleSize)
        return
      }
      return window.removeEventListener('resize', handleSize)
    }
  }, [])
  return (
    <>
      <DawnLight width={width} medium={width / 2} />
      <DawnDark width={width} medium={width / 2} />
    </>
  )
}

export default Dawn
