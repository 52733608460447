import styled from 'styled-components'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import useNewsletterForm from 'hooks/use-newsletter-form'
import TextField, { LightTextField, TextFieldSimple } from 'common/text-field'
import { SecondaryButton } from 'common/button'
import Message from 'common/message'

const FormStyled = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, min-content);
  row-gap: 24px;
  position: relative;
  .message-container {
    max-width: 90%;
  }
  .loading {
    position: absolute;
    right: 0px;
    bottom: 5px;
  }
  .MuiCircularProgress-colorPrimary{
    color: var(--white);
  }
  @media(min-width: 768px) {
    .loading {
      position: absolute;
      right: 15px;
      bottom: 5px;
    }
    button {
      width: ${({ btnFullWidth }) => !btnFullWidth && 'fit-content'};
    }
    .btn-container {
      display: flex;
      justify-content: flex-start;
    }
  }
`

const nameProps = {
  name: 'NOMBRE',
  required: true,
  placeholder: 'Escribe tu nombre',
  label: 'Nombre',
  fullWidth: true,
  errorMessage: 'Ingresa tu nombre por favor :)',
}

const emailProps = {
  name: 'EMAIL',
  required: true,
  type: 'email',
  placeholder: 'Escribe tu email',
  label: 'Email',
  fullWidth: true,
  errorMessage: 'Ingresa tu email por favor :)',
}

function NewsletterForm({
  sendinblueConfig, cta, formName, lightForm, btnMedium, btnFullWidth, authomatic, urlForm, simpleForm,
}) {
  const nameId = `${formName}-name`
  const emailId = `${formName}-email`
  const formId = `form-${formName}`
  const {
    ref, message, success, handleSubmit, loading,
  } = useNewsletterForm(sendinblueConfig, authomatic, urlForm)
  return (
    <FormStyled ref={ref} onSubmit={handleSubmit} btnFullWidth={btnFullWidth}>
      {lightForm && !simpleForm && (
        <>
          <LightTextField id={nameId} {...nameProps} />
          <LightTextField id={emailId} {...emailProps} />
        </>
      )}
      {simpleForm && (
        <>
          <TextFieldSimple {...nameProps} />
          <TextFieldSimple {...emailProps} />
        </>
      )}
      {!simpleForm && !lightForm && (
        <>
          <TextField id={nameId} {...nameProps} />
          <TextField id={emailId} {...emailProps} />
        </>
      )}
      <div className="btn-container">
        <SecondaryButton lineHeight="24px" fontWeight={500} type="submit" medium={btnMedium} fullWidth={btnFullWidth}>
          {cta}
        </SecondaryButton>
      </div>
      {message && (
        <div className="message-container">
          <Message
            marginTop="0"
            marginLeft="0"
            id={success ? formId : `${formId}-error`}
            error={!success}
            color={lightForm && 'white'}
            fontSize="16px"
          >
            {message}
          </Message>
        </div>
      )}
      {loading && (
        <div className="loading">
          <CircularProgress size="25px" />
        </div>
      )}
    </FormStyled>
  )
}

NewsletterForm.defaultProps = {
  sendinblueConfig: {
    list: null,
    template: null,
    redirect: null,
  },
  urlForm: 'MUIEAEyMCeXmZLSAKICzA_23z_ALAr5b_70YsWA_NoL_fecgZuy02WTqGHwvn7hRRwXqLKgTLtzPmvE0JX8QFiZXBi81j2MTenOujV6lTrhLzYfygCfdolfKlc4ElAcA7ZagZ7Lt-dCbf84SWbJ7XnID2PWIcULlMuCVKMXMRyQCmGa4td7GuCz8MMdC7A8AM0VWCkwHbBplKrIj',
  cta: 'Suscribirse',
  formName: 'newsletter',
  lightForm: false,
  btnMedium: false,
  btnFullWidth: false,
  authomatic: false,
}

NewsletterForm.propTypes = {
  sendinblueConfig: PropTypes.shape({
    list: PropTypes.string,
    template: PropTypes.string,
    redirect: PropTypes.string,
  }),
  urlForm: PropTypes.string,
  cta: PropTypes.string,
  formName: PropTypes.string,
  lightForm: PropTypes.bool,
  btnMedium: PropTypes.bool,
  btnFullWidth: PropTypes.bool,
  authomatic: PropTypes.bool,
}

export default NewsletterForm
