import MaterialTextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

const TextFieldStyled = styled(MaterialTextField)`
  .MuiOutlinedInput-root {
    width: ${({ width }) => width} !important;
    border-radius: 4px;
    &:hover .MuiOutlinedInput-notchedOutline{
      border:1px solid ${({ darkactivebordercolor }) => darkactivebordercolor};
    }
  }
  .MuiInput-underline:after {
    border-bottom-color: ${({ textfield }) => textfield} !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ textfield }) => textfield};
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: ${({ darkactivebordercolor }) => darkactivebordercolor} !important;
    border-width: 1px !important;
  }
  .MuiFormLabel-filled, .Mui-focused.MuiInputLabel-animated {
    padding: 3px;
    border-radius: 6px 6px 0 0;
  }
  .MuiFormLabel-root {
    color: ${({ textfield }) => textfield};
    background-color: ${({ background }) => background};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${({ darkactivebordercolor }) => darkactivebordercolor} !important;
  }
  .MuiInputBase-root {
    color: ${({ textcolor }) => textcolor};
    background-color: ${({ background }) => background || 'var(--basePrimary)'};
  }
  .MuiSelect-select:focus {
    background: none;
  }
  .Mui-error.MuiFormHelperText-root {
    color: ${({ errorcolor }) => errorcolor}!important;
  }
  .MuiOutlinedInput-input {
    padding: 14.5px 14px;
  }
  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 16px) scale(1);
    pointer-events: none;
  }
  .MuiInputBase-root.Mui-disabled {
    color: var(--gray);
    fieldset{
      border-color: var(--gray)!important;
    }
  }
  .MuiFormLabel-root.Mui-disabled {
    color: var(--gray);
  }
`
// TextFieldStyled.passProps(false)

function TextField({
  onBlur,
  onChange,
  required,
  InputProps,
  helperText,
  error,
  errorColor,
  color,
  textField,
  activeBorderColor,
  darkActiveBorderColor,
  errorMessage,
  disabled,
  textColor,
  background,
  width,
  ...otherProps
}) {
  const [defaultError, setDefaultError] = useState('')
  const input = useRef(null)
  const ref = (InputProps && InputProps.inputRef) ? InputProps.inputRef : input
  function checkValidity() {
    if (required) {
      if (!ref.current.checkValidity()) {
        setDefaultError(errorMessage)
      } else {
        setDefaultError('')
      }
    }
  }
  function handleChange(event) {
    checkValidity()
    if (onChange) {
      onChange(event)
    }
  }
  function handleBlur(event) {
    checkValidity()
    if (onBlur) {
      onBlur(event)
    }
  }
  return (
    <TextFieldStyled
      InputProps={{
        inputRef: (InputProps && InputProps.inputRef) ? InputProps.inputRef : ref,
        ...InputProps,
      }}
      background={background}
      textcolor={textColor}
      textfield={textField}
      activebordercolor={activeBorderColor}
      darkactivebordercolor={darkActiveBorderColor}
      errorcolor={errorColor}
      error={error || !!defaultError}
      helperText={helperText || defaultError}
      width={width}
      onBlur={handleBlur}
      required={required}
      onChange={handleChange}
      color={color}
      variant="outlined"
      disabled={disabled}
      {...otherProps}
    />
  )
}

TextField.defaultProps = {
  errorColor: '#f44336',
  textColor: 'var(--baseSecondary)',
  textField: 'var(--textField)',
  activeBorderColor: 'var(--baseSecondary)',
  darkActiveBorderColor: 'var(--primary)',
  disabled: false,
}

TextField.propTypes = {
  errorColor: PropTypes.string,
  textColor: PropTypes.string,
  textField: PropTypes.string,
  activeBorderColor: PropTypes.string,
  darkActiveBorderColor: PropTypes.string,
  disabled: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
}

export function LightTextField(props) {
  return (
    <TextField
      {...props}
      textcolor="var(--black)"
      background="var(--white)"
      textfield="var(--black)"
      activeborderbolor="var(--black)"
      darkactivebordercolor="var(--primary)"
      errorcolor="var(--white)"
    />
  )
}

const TextFieldSimpleStyled = styled.div`
  label {
    display: none;
  }
  input {
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 0 14px;
    border-radius: 4px;
    color: var(--black);
    box-sizing: border-box;
  }
`

export function TextFieldSimple({ label, name, type, handleChange, value }) {
  return (
    <TextFieldSimpleStyled>
      <label htmlFor={label}>{label}</label>
      <input
        onChange={handleChange}
        placeholder={label}
        value={value}
        name={name}
        type={type}
        id={label}
      />
    </TextFieldSimpleStyled>
  )
}

export default TextField
