import styled from 'styled-components'
import Image from 'next/legacy/image'
import NewsletterForm from 'common/newsletter/newsletter-form'
import { Typography } from 'common/typography'
import Wrapper from 'common/wrapper'
import Dawn from './animations/dawn'

const PlanetSectionStyled = styled.section`
  .bottom-section {
    position: relative;
    background-image: url(/images/home/planet-surface.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .newsletter {
    text-align: center;
    color: var(--baseSecondary);
    padding: 0 16px;
    margin: 0 auto;
    max-width: 588px;
    p:first-of-type {
      margin-top: 32px;
    }
    p:last-of-type {
      margin-bottom: 32px;
    }
  }
  .moons {
    margin-bottom: 80px;
    width: 200px;
  }
  .antenna {
    margin-top: 100px;
    display: flex;
    justify-content: flex-end;
    & > div {
      width: 110px!important;
    }
  }
  @media (min-width: 1024px) {
    .moons {
      margin-bottom: 70px;
      width: auto;
    }
    .bottom-section {
      background-position: bottom, 0 -30px;
    }
    .antenna > div {
      width: 227px!important;
    }
  }
`

function PlanetSection() {
  return (
    <PlanetSectionStyled aria-labelledby="newsletter-section-title">
      <div className="bottom-section">
        <div className="moons">
          <Image width={384} height={311} src="/images/home/home-planets.png" alt="Ilustracion de una gran luna rosada con morado acompañadad de una pequeña luna azul a su lado" />
        </div>
        <div className="newsletter">
          <Typography small="headline5" large="headline4" as="h2" id="newsletter-section-title">
            Suscríbete a nuestro newsletter
          </Typography>
          <Typography>
            Streamings, Noticias y Early Adopter bonus.
          </Typography>
          <Typography>
            Sé el primero en enterarte de todo.
          </Typography>
          <NewsletterForm
            btnFullWidth
            simpleForm
            authomatic
            lightForm
            formName="footer"
            urlForm="MUIEAIzdxLEprCHWB8Biz_SSxLcx0Giov9x_auLjki5UisgmWoKjwXtWEPWhe0p0N3pdQQzwncOwTz2y7Ht-mpw_peZIwm7GoB4Arq3aczde9j0OhcLhT2vwMHqU27kxPJ66dwviQQoI33YN1_3kFVkL49YSC_C5HMXvJaoiJDOkyvZ7DXlCOqXS4YOQyuTIeuXYdWNYw4mKzeg5"
          />
        </div>
        <Wrapper>
          <div className="antenna">
            <Image width={227} height={304.68} src="/images/home/ant-home.png" alt="Antena futurista y fosforecente en la superficie de un planeta apuntando hacia las lunas del mismo." />
          </div>
        </Wrapper>
        <Dawn />
      </div>
    </PlanetSectionStyled>
  )
}

export default PlanetSection
