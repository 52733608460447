import styled from 'styled-components'
import { ANIMATION_DURATION } from 'home/animation-duration'

const DawnLightStyled = styled.svg`
  position: absolute;
  bottom: 0;
  z-index: -1;

  .g1-s1 {
    animation: g1-s1 ${ANIMATION_DURATION} infinite linear alternate;
  }
  @keyframes g1-s1 {
    from {
      stop-color: #06002D;
    }
    50% {
      stop-color: #D2B2F9;
    }
    to {
      stop-color: #FA057C;
    }
  }



  .g1-s2 {
    animation: g1-s2 ${ANIMATION_DURATION} infinite linear alternate;
  }
  @keyframes g1-s2 {
    from {
      stop-color: #DFFBFF;
    }
    50% {
      stop-color: #DFFBFF;
    }
    to {
      stop-color: #DFFBFF;
    }
  }


  .g2-s1 {
    animation: g2-s1 ${ANIMATION_DURATION} infinite linear alternate;
  }
  @keyframes g2-s1 {
    from {
      stop-color: #FA057C;
    }
    50% {
      stop-color: #089CB9;
    }
    to {
      stop-color: #0704FE;
    }
  }


  .g2-s2 {
    animation: g2-s2 ${ANIMATION_DURATION} infinite linear alternate;
  }
  @keyframes g2-s2 {
    from {
      stop-color: #D2B2F9;
    }
    50% {
      stop-color: #D2B2F9;
    }
    to {
      stop-color: #D2B2F9;
    }
  }


  .g2-s3 {
    animation: g2-s3 ${ANIMATION_DURATION} infinite linear alternate;
  }
  @keyframes g2-s3 {
    from {
      stop-color: #DFFBFF;
    }
    50% {
      stop-color: #DFFBFF;
    }
    to {
      stop-color: #DFFBFF;
    }
  }


  .g3-s1 {
    animation: g3-s1 ${ANIMATION_DURATION} infinite linear alternate;
  }
  @keyframes g3-s1 {
    from {
      stop-color: #EB6C7C;
    }
    50% {
      stop-color: #10FEFE;
    }
    to {
      stop-color: #10FEFE;
    }
  }


  .g3-s2 {
    animation: g3-s2 ${ANIMATION_DURATION} infinite linear alternate;
  }
  @keyframes g3-s2 {
    from {
      stop-color: #EB6C7C;
    }
    50% {
      stop-color: #0AB3C9;
    }
    to {
      stop-color: #0AB3C9;
    }
  }


  .g3-s3 {
    animation: g3-s3 ${ANIMATION_DURATION} infinite linear alternate;
  }
  @keyframes g3-s3 {
    from {
      stop-color: #EB6C7C;
    }
    50% {
      stop-color: #DFFBFF;
    }
    to {
      stop-color: #DFFBFF;
    }
  }
  display: none;
`

function DawnLight({ width, medium }) {
  return (
    <DawnLightStyled width={width} height="939" viewBox={`0 0 ${width} 939`} fill="none">
      <path d={`M${width} 0H0V910.667H${width}V0Z`} fill="url(#dawn_light_radial)" />
      <path d={`M${width} 0H0V910.667H${width}V0Z`} fill="url(#dawn_light_radial_2)" />
      <path d={`M${width} 0H0V910.667H${width}V0Z`} fill="url(#dawn_light_radial_3)" />
      <defs>
        <radialGradient id="dawn_light_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientUnits="userSpaceOnUse" gradientTransform={`translate(${medium} 910.667) rotate(-90) scale(758.889 1441.89)`}>
          <stop className="g1-s1" stopColor="#3B1375" />
          <stop className="g1-s2" offset="1" stopColor="#0A004E" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="dawn_light_radial_2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform={`translate(${medium} 910.667) rotate(-90) scale(629.878 1593.67)`}>
          <stop className="g2-s1" stopColor="#089CB9" />
          <stop className="g2-s2" offset="0.55" stopColor="#0730EA" stopOpacity="0.29" />
          <stop className="g2-s3" offset="1" stopColor="#0704FE" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="dawn_light_radial_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform={`translate(${medium} 910.667) rotate(-90) scale(303.556 1290.11)`}>
          <stop className="g3-s1" stopColor="#10FEFE" />
          <stop className="g3-s2" offset="0.48" stopColor="#0AB3C9" stopOpacity="0.24" />
          <stop className="g3-s3" offset="1" stopColor="#089CB9" stopOpacity="0" />
        </radialGradient>
      </defs>
    </DawnLightStyled>
  )
}

export default DawnLight
