import styled from 'styled-components'

const DawnDarkStyled = styled.svg`
  display: none;
  position: absolute;
  bottom: -30px;
  z-index: -1;
  max-width: 100vw;
  @media(min-width: 1024px) {
    bottom: 0px;
  }

  .g1-s1 {
    animation: g1-s1 10s infinite linear alternate;
  }
  @keyframes g1-s1 {
    from {
      stop-color: #06002D;
    }
    50% {
      stop-color: #3B1375;
    }
    to {
      stop-color: #FA057C;
    }
  }



  .g1-s2 {
    animation: g1-s2 10s infinite linear alternate;
  }
  @keyframes g1-s2 {
    from {
      stop-color: #06002D;
    }
    50% {
      stop-color: #0A004E;
    }
    to {
      stop-color: #3B1375;
    }
  }


  .g2-s1 {
    animation: g2-s1 10s infinite linear alternate;
  }
  @keyframes g2-s1 {
    from {
      stop-color: #FA057C;
    }
    50% {
      stop-color: #089CB9;
    }
    to {
      stop-color: #0704FE;
    }
  }


  .g2-s2 {
    animation: g2-s2 10s infinite linear alternate;
  }
  @keyframes g2-s2 {
    from {
      stop-color: #3B1375;
    }
    50% {
      stop-color: #0730EA;
    }
    to {
      stop-color: #2C0F9D;
    }
  }


  .g2-s3 {
    animation: g2-s3 10s infinite linear alternate;
  }
  @keyframes g2-s3 {
    from {
      stop-color: #3B1375;
    }
    50% {
      stop-color: #0704FE;
    }
    to {
      stop-color: #3B1375;
    }
  }


  .g3-s1 {
    animation: g3-s1 10s infinite linear alternate;
  }
  @keyframes g3-s1 {
    from {
      stop-color: #EB6C7C;
    }
    50% {
      stop-color: #10FEFE;
    }
    to {
      stop-color: #10FEFE;
    }
  }


  .g3-s2 {
    animation: g3-s2 10s infinite linear alternate;
  }
  @keyframes g3-s2 {
    from {
      stop-color: #EB6C7C;
    }
    50% {
      stop-color: #0AB3C9;
    }
    to {
      stop-color: #0AB3C9;
    }
  }


  .g3-s3 {
    animation: g3-s3 10s infinite linear alternate;
  }
  @keyframes g3-s3 {
    from {
      stop-color: #EB6C7C;
    }
    50% {
      stop-color: #089CB9;
    }
    to {
      stop-color: #089CB9;
    }
  }
  display: block;
`

function DawnDark({ width, medium }) {
  return (
    <DawnDarkStyled width={width} height="939" viewBox={`0 0 ${width} 939`} fill="none">
      <path d={`M${width} 0H0V910.667H${width}V0Z`} fill="url(#dawn_dark_1)" />
      <path d={`M${width} 0H0V910.667H${width}V0Z`} fill="url(#dawn_dark_2)" />
      <path d={`M${width} 0H0V910.667H${width}V0Z`} fill="url(#dawn_dark_3)" />
      <defs>
        <radialGradient id="dawn_dark_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform={`translate(${medium} 910.667) rotate(-90) scale(758.889 1441.89)`}>
          <stop className="g1-s1" stopColor="#3B1375" />
          <stop className="g1-s2" offset="1" stopColor="#0A004E" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="dawn_dark_2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform={`translate(${medium} 910.667) rotate(-90) scale(629.878 1593.67)`}>
          <stop className="g2-s1" stopColor="#089CB9" />
          <stop className="g2-s2" offset="0.55" stopColor="#0730EA" stopOpacity="0.29" />
          <stop className="g2-s3" offset="1" stopColor="#0704FE" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="dawn_dark_3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform={`translate(${medium} 910.667) rotate(-90) scale(303.556 1290.11)`}>
          <stop className="g3-s1" stopColor="#10FEFE" />
          <stop className="g3-s2" offset="0.48" stopColor="#0AB3C9" stopOpacity="0.24" />
          <stop className="g3-s3" offset="1" stopColor="#089CB9" stopOpacity="0" />
        </radialGradient>
      </defs>
    </DawnDarkStyled>
  )
}

export default DawnDark
