import styled from 'styled-components'
import { P } from './typography'

const Message = styled(P)`
  color: ${({ error }) => (error ? 'var(--error)' : 'var(--success)')};
  ${({ color }) => (color && `color: ${color}; font-weight: 500;`)}
  margin-top: ${({ marginTop }) => (marginTop || '15px')};
  margin-left: ${({ marginLeft }) => (marginLeft || '10px')};
  margin: ${({ margin }) => (margin)};
  font-weight: ${({ weight }) => (weight)};
  font-size: ${({ fontSize }) => (fontSize || '14px')};
  line-height: ${({ lineHeight }) => lineHeight};
  text-align: ${({ align }) => (align || 'left')};
  position: ${({ position }) => position};
  bottom: ${({ bottom }) => bottom};
  top: ${({ top }) => top};
  a {
    color: var(--turquoise);
  }
`

export default Message
