import { useRef, useState } from 'react'

function useNewsletterForm(sendinblueConfig, automatic, urlForm) {
  const ref = useRef(null)
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState(null)
  const [loading, setLoading] = useState(false)
  async function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData(ref.current)
    if (automatic) {
      const { EMAIL: email, NOMBRE: name } = Object.fromEntries(formData)
      const withoutFields = !email && !name
      const withoutName = email && !name
      const withoutEmail = !email && name
      if ( withoutFields || withoutName || withoutEmail) {
        setMessage('Por favor llena todos los campos correctamente')
        setSuccess(false)
        setLoading(false)
        return
      }
      const res = await fetch(`/api/automatization?urlForm=${urlForm}`, {
        method: 'POST',
        body: JSON.stringify({ name, email }),
      })
      if (res.status === 200) {
        setMessage('¡Genial! te llegará un correo para que confirmes tu suscripción')
        setSuccess(true)
      } else {
        setMessage('Ha ocurrido un error :(')
        setSuccess(false)
      }
      setLoading(false)
      return
    }
    const name = formData.get('NOMBRE')
    const email = formData.get('EMAIL')
    const redirectionUrl = window.location.href
    const response = await fetch('/api/sendinblue', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        redirectionUrl,
        config: sendinblueConfig || {
          list: null,
          template: null,
          redirect: null,
        },
      }),
    })
    if (response.status === 201) {
      setMessage('¡Genial! te llegará un correo para que confirmes tu suscripción')
      setSuccess(true)
    } else if (response.status === 204) {
      setMessage('Ya estas suscrito, pero áun no has verificado tu email')
      setSuccess(false)
    } else {
      setMessage('Ha ocurrido un error :(')
      setSuccess(false)
    }
    setLoading(false)
  }
  return ({
    ref, message, success, handleSubmit, loading,
  })
}

export default useNewsletterForm
